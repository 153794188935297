import { gql } from '@apollo/client';

export default gql`
  query productionOrderCountsByState($legacy: Boolean) {
    productionOrderCountsByState(legacy: $legacy) {
      hold
      pending
      predesign
      inDesign
      designQa
      redesign
      designHold
      atManufacturer
      manufacturing
      manufacturingHold
      shippedToHq
      receivedAtHq
      holdAtHq
      shippingHold
      collectingShipment
      shippedToRecipient
      deliveredToRecipient
      returnedToHq
      cancelled
    }
  }
`;
