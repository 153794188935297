import { ApolloClient, HttpLink, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { fetchAuthSession, signOut } from 'aws-amplify/auth';

function signout() {
  signOut()
    .then(() => {
      // client.resetStore();
      window.localStorage.removeItem('apollo-cache-persist');
    })
    .catch((err) => {
      console.error(err);
    });
}

const httpLink = new HttpLink({
  uri: import.meta.env.VITE_API_SERVER,
  credentials: 'same-origin',
});

const authLink = setContext(async (_, { headers }) => {
  // if you have a cached value, return it immediately
  try {
    const { idToken } = (await fetchAuthSession()).tokens ?? {};

    return {
      headers: {
        ...headers,
        authorization: idToken ? `Bearer ${idToken}` : '',
        'x-app-name': import.meta.env.VITE_APP_NAME,
      },
    };
  } catch (err) {
    console.error('Error fetching auth session', err);
    return {
      headers: {
        ...headers,
        'x-app-name': import.meta.env.VITE_APP_NAME,
      },
    };
  }
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  console.error(graphQLErrors, 'onError - graphqlerrors - apolloClient');
  console.error(networkError, 'onError - networkerror - apolloClient');

  if (graphQLErrors) {
    for (const { status = 200 } of graphQLErrors) {
      console.error(status, 'onError - graphqlerrors - status');

      if (status === 401) {
        console.error("You've attempted to access an unauthorized section");
      }

      if (status === 403) {
        console.error("You've attempted a forbidden action");
      }
    }
  }

  if (networkError) {
    console.error('Network Error:', networkError);

    if (networkError.statusCode === 401) {
      console.warn('Unauthorized access. Signing out...');
      signout();
    } else if (networkError.statusCode === 403) {
      console.warn('Forbidden action attempted');
    }
  }
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        // Add field policies here if needed
      },
    },
  },
});

const apolloClient = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
  connectToDevTools: true,
});

export default apolloClient;
