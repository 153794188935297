import PropTypes from 'prop-types';
import { mapHref } from '../../../transforms';
import Card from '../../Card/Card';
import RcDl from '../../RcDl/RcDl';
import RcLink from '../../RcLink/RcLink';
import ShowDetailsSection from '../ShowDetails/ShowDetailsSection';
import ShowLocationMap from './ShowLocationMap';

function ShowLocation({ address, shippingAddress = null, className = '' }) {
  return (
    <Card
      className={`h-full w-full overflow-hidden ${className}`}
      wrapperClassName="h-full"
    >
      <ShowLocationMap address={address} />
      <RcDl className="px-4 py-2">
        <ShowDetailsSection title="Address">
          <RcLink
            href={mapHref(address.latitude, address.longitude)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {address.address}
            <br />
            {address.address2}
            {address.address2 ? <br /> : null}
            {address.city}, {address.region} {address.postalCode}
            <br />
            {address.country}
          </RcLink>
        </ShowDetailsSection>
        {shippingAddress && (
          <ShowDetailsSection title="Shipping Address">
            <RcLink
              href={mapHref(
                shippingAddress.latitude,
                shippingAddress.longitude,
              )}
              target="_blank"
              rel="noopener noreferrer"
            >
              {shippingAddress.address}
              <br />
              {shippingAddress.address2}
              {shippingAddress.address2 ? <br /> : null}
              {shippingAddress.city}, {shippingAddress.region}&nbsp;
              {shippingAddress.postalCode}
              <br />
              {shippingAddress.country}
            </RcLink>
          </ShowDetailsSection>
        )}
      </RcDl>
    </Card>
  );
}

ShowLocation.propTypes = {
  address: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default ShowLocation;
