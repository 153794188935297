import {
  EyeIcon,
  ListBulletIcon,
  PencilSquareIcon,
  PlusIcon,
} from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import TooltipText from '../Tooltip/TooltipText';

const sizeClasses = {
  xs: 'px-2 py-1 text-xs',
  sm: 'px-2 py-1 text-sm',
  md: 'px-2.5 py-1.5 text-sm',
  lg: 'px-3 py-2 text-base',
  xl: 'px-3.5 py-2.5 text-base',
};

const colorClasses = {
  indigo:
    'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600 text-white',
  red: 'bg-red-600 hover:bg-red-500 focus-visible:outline-red-600 text-white',
  gray: 'bg-gray-600 hover:bg-gray-500 focus-visible:outline-gray-600 text-white',
  yellow:
    'bg-yellow-600 hover:bg-yellow-500 focus-visible:outline-yellow-600 text-white',
  green:
    'bg-green-600 hover:bg-green-500 focus-visible:outline-green-600 text-white',
  blue: 'bg-blue-600 hover:bg-blue-500 focus-visible:outline-blue-600 text-white',
  pink: 'bg-pink-600 hover:bg-pink-500 focus-visible:outline-pink-600 text-white',
  purple:
    'bg-purple-600 hover:bg-purple-500 focus-visible:outline-purple-600 text-white',
  rcprimary:
    'bg-rcprimary-400 hover:bg-rcprimary-400/0 text-white outline outline-white/0 outline-2 hover:text-rcprimary-400 hover:outline-rcprimary-400 outline-offset-0',
};

function Button({
  name = '',
  show = false,
  edit = false,
  add = false,
  list = false,
  size = 'md',
  rounded = false,
  disabled = false,
  color = 'rcprimary',
  onClick = null,
  to = null,
  className = '',
  tooltip = null,
  tooltipPosition = 'tooltip-top',
}) {
  const getSize = () => sizeClasses[size] || sizeClasses.md;
  const getColor = () => colorClasses[color] || colorClasses.rcprimary;

  const buttonClasses = `inline-flex items-center gap-x-1.5 font-medium shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${getSize()} ${
    rounded ? 'rounded-xl' : ''
  } ${getColor()} ${className} ${tooltip ? 'tooltip' : ''} ${
    tooltip && tooltipPosition ? tooltipPosition : ''
  } ${disabled ? 'cursor-not-allowed disabled:opacity-60 disabled:bg-rcgray-400 disabled:text-black' : ''}`;

  const iconProps = { className: '-ml-0.5 size-5', 'aria-hidden': 'true' };

  const content = (
    <>
      {tooltip && <TooltipText tooltip={tooltip} />}
      {edit && <PencilSquareIcon {...iconProps} />}
      {list && <ListBulletIcon {...iconProps} />}
      {add && <PlusIcon {...iconProps} />}
      {show && <EyeIcon {...iconProps} />}
      {name}
    </>
  );

  return to ? (
    <NavLink to={to} disabled={disabled} className={buttonClasses}>
      {content}
    </NavLink>
  ) : (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={buttonClasses}
    >
      {content}
    </button>
  );
}

Button.propTypes = {
  name: PropTypes.string,
  edit: PropTypes.bool,
  show: PropTypes.bool,
  add: PropTypes.bool,
  list: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  rounded: PropTypes.bool,
  color: PropTypes.oneOf([
    'indigo',
    'red',
    'gray',
    'yellow',
    'green',
    'blue',
    'pink',
    'purple',
    'rcprimary',
  ]),
  onClick: PropTypes.func,
  to: PropTypes.string,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  tooltipPosition: PropTypes.string,
};

export default Button;
