import PropTypes from 'prop-types';
import ShowContact from '../ShowContact/ShowContact';
import ShowLocation from '../ShowLocation/ShowLocation';

function ShowBody({
  children,
  rightColumn = null,
  address = null,
  shippingAddress = null,
  email = null,
  phone = null,
  website = null,
  hideContact = false,
  user = null,
  account = null,
}) {
  return (
    <div className="flex flex-col gap-2 md:flex-row">
      <div className="flex basis-1 flex-col md:basis-full lg:basis-1/3">
        {children}
      </div>

      {!hideContact && (
        <div className="flex basis-1 flex-col md:basis-1/2 lg:basis-1/3">
          <ShowContact
            email={email}
            phone={phone}
            website={website}
            user={user}
            account={account}
          />
        </div>
      )}

      {address && (
        <div className="flex basis-1 flex-col md:basis-1/2 lg:basis-1/3">
          <ShowLocation address={address} shippingAddress={shippingAddress} />
        </div>
      )}

      {rightColumn && (
        <div className="flex basis-1 flex-col md:basis-1/2 lg:basis-1/3">
          {rightColumn}
        </div>
      )}
    </div>
  );
}

ShowBody.propTypes = {
  children: PropTypes.node.isRequired,
  rightColumn: PropTypes.node,
  address: PropTypes.object,
  email: PropTypes.string,
  phone: PropTypes.string,
  website: PropTypes.string,
  hideContact: PropTypes.bool,
  user: PropTypes.object,
  account: PropTypes.object,
  shippingAddress: PropTypes.object,
};

export default ShowBody;
