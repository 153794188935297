import { XCircleIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BaseLoader from './BaseLoader';

function LoadingOrError({
  title = '',
  loading,
  error = null,
  hideText = false,
  className = '',
}) {
  const { t } = useTranslation('ui');

  if (loading) {
    return (
      <BaseLoader title={title} hideText={hideText} className={className} />
    );
  }

  if (error) {
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="shrink-0">
            <XCircleIcon className="size-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="font-medium text-red-800 text-sm">
              {t('We encountered a problem')}
            </h3>
            <div className="mt-2 text-red-700 text-sm">
              <ul className="list-disc space-y-1 pl-5">
                {error.graphQLErrors.map((err) => (
                  <li
                    key={
                      err.path?.join('.') || err.extensions?.code || err.message
                    }
                  >
                    {t(err.message)}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LoadingOrError.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  hideText: PropTypes.bool,
  className: PropTypes.string,
};

export default LoadingOrError;
