import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react';
import {
  CheckCircleIcon,
  ChevronDownIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/20/solid';
import { useAccount } from '@retainerclub/hooks';

function ShopPatient() {
  const { patients, currentPatient, setCurrentPatient } = useAccount();

  function handlePatientChange(id) {
    setCurrentPatient(patients.find((patient) => patient.id === id));
  }

  return (
    <div className="mx-auto flex justify-center">
      <Menu as="div" className="relative inline-block">
        <div className="flex">
          <MenuButton className="group inline-flex justify-center font-medium text-gray-700 text-sm hover:text-gray-900">
            Shopping for:&nbsp;
            <span className="ml-2 font-bold">
              {currentPatient
                ? `${currentPatient.firstName} ${currentPatient.lastName}`
                : patients.length > 0
                  ? `${patients[0].firstName} ${patients[0].lastName}`
                  : 'Patients'}
            </span>
            <ChevronDownIcon
              className="-mr-1 ml-1 size-5 shrink-0 text-gray-400 group-hover:text-gray-500"
              aria-hidden="true"
            />
          </MenuButton>
        </div>

        <Transition
          as="div"
          className="absolute right-0 z-20 mt-2 w-80 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black/5 transition duration-100 ease-out focus:outline-none data-[closed]:scale-95 data-[enter]:scale-100 data-[leave]:scale-95 data-[closed]:opacity-0 data-[enter]:opacity-100 data-[leave]:opacity-0"
        >
          <MenuItems className="absolute right-0 z-20 mt-2 w-80 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black/5 focus:outline-none">
            <div className="py-1">
              {[...patients]
                .sort((a, b) => a.firstName.localeCompare(b.firstName))
                .map((patient) => (
                  <MenuItem key={patient.id}>
                    {({ active }) => (
                      <button
                        onClick={() => handlePatientChange(patient.id)}
                        type="button"
                        className={`${
                          patient.id === currentPatient?.id
                            ? 'font-medium text-gray-900 '
                            : 'text-gray-500 '
                        } ${active ? 'bg-gray-100' : ''} flex w-full flex-row items-center justify-between px-4 py-2 text-sm`}
                      >
                        <span>
                          {patient.firstName} {patient.lastName}
                        </span>
                        {!patient.hasPlan && (
                          <span className="flex flex-row gap-2 font-bold text-rccallouts-warning text-xs">
                            <ExclamationTriangleIcon className="size-4" />
                            No Current Plan
                          </span>
                        )}

                        {patient.hasPlan && (
                          <span className="flex flex-row gap-2 font-bold text-rcprimary-400 text-xs">
                            <CheckCircleIcon className="size-4" />
                            Enrolled
                          </span>
                        )}
                      </button>
                    )}
                  </MenuItem>
                ))}
            </div>
          </MenuItems>
        </Transition>
      </Menu>
    </div>
  );
}

export default ShopPatient;
