import PropTypes from 'prop-types';

import PrivacyPolicy from './Policies/PrivacyPolicy';

import TermsAndConditions from './Policies/TermsAndConditions';

function Policy({ policy }) {
  return policy === 'terms' ? <TermsAndConditions /> : <PrivacyPolicy />;
}

Policy.propTypes = {
  policy: PropTypes.string.isRequired,
};

export default Policy;
