import { Header } from '@retainerclub/customer-shared';
import { Outlet } from 'react-router-dom';
import Footer from './Footer/Footer';

function MainLayout() {
  return (
    <div className="relative flex min-h-full flex-col bg-white">
      <Header className="fixed z-20 w-full" />

      <main className="relative mt-[68px] pb-[100px] lg:pb-[200px]">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}

export default MainLayout;
