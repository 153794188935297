import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth, usePolicy } from '@retainerclub/hooks';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ErrorMessage, SubmitButton } from '../../../shared';

function AuthFormContainer({
  children,
  links,
  submitFunction,
  submitTitle,
  initialValues,
  schema,
  showLegal = false,
  clickAction = 'Sign In',
}) {
  const { setPolicyAndOpenModal } = usePolicy();
  const { error } = useAuth();

  const { t } = useTranslation('signin');

  const {
    register,
    handleSubmit,
    formState: { isLoading, isSubmitting },
    formState,
    control,
    getValues,
    setValue,
    ...rest
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
    mode: 'onBlur',
  });

  return (
    <FormProvider
      {...rest}
      control={control}
      register={register}
      handleSubmit={handleSubmit}
      formState={formState}
      getValues={getValues}
      setValue={setValue}
    >
      <form
        onSubmit={handleSubmit(submitFunction)}
        className="space-y-4"
        noValidate
      >
        {children}

        {links.map((link) => (
          <div key={link.id} className="flex items-center justify-center">
            <div className="text-sm">
              <span className="text-black">
                {link?.preText && t(link.preText)}
              </span>
              <Link
                to={link.to}
                className="font-medium text-rcprimary-400 hover:text-rcprimary-500"
              >
                {t(link.text)}
              </Link>
            </div>
          </div>
        ))}

        <div>
          <SubmitButton
            isLoading={isLoading}
            isSubmitting={isSubmitting}
            label={submitTitle}
            notRounded
          />
        </div>

        {showLegal && (
          <div>
            <p className="text-center text-black text-sm">
              By clicking&nbsp;
              <b>
                <i>{clickAction}</i>
              </b>
              &nbsp; you are agreeing to our&nbsp;
              <button
                type="button"
                className="text-rcprimary-500 hover:text-rcprimary-600"
                onClick={() => setPolicyAndOpenModal('terms')}
              >
                Terms and Conditions
              </button>
              &nbsp; and&nbsp;
              <button
                type="button"
                className="text-rcprimary-500 hover:text-rcprimary-600"
                onClick={() => setPolicyAndOpenModal('privacy')}
              >
                Privacy Policy
              </button>
              .
            </p>
          </div>
        )}

        <ErrorMessage error={error} />
      </form>
    </FormProvider>
  );
}

AuthFormContainer.propTypes = {
  children: PropTypes.node.isRequired,
  schema: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  links: PropTypes.array.isRequired,
  submitFunction: PropTypes.func.isRequired,
  submitTitle: PropTypes.string.isRequired,
  showLegal: PropTypes.bool,
  clickAction: PropTypes.string,
};

export default AuthFormContainer;
