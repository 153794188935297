import { EnvelopeIcon, LockClosedIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormErrors from './FormErrors';

function FieldContainer({
  children,
  name,
  label = null,
  style = {},
  hideLabel = false,
  required = false,
  className = '',
  type = 'text',
  website = false,
  file = false,
}) {
  const { t } = useTranslation('forms');
  const {
    formState: { errors },
  } = useFormContext();

  const iconClasses = `h-5 w-5 text-gray-400 ${
    errors?.[name] ? 'text-red-300' : ''
  }`;

  return (
    <div style={style} className={className}>
      {!hideLabel && (
        <label
          className="block font-medium text-black/70 text-sm capitalize leading-6"
          htmlFor={name}
        >
          {t(label || name)}
          {required && <span className="text-red-400">*</span>}
        </label>
      )}
      {website && !file && (
        <div className="mt-1 md:mt-2">
          <div className="flex rounded-md bg-white shadow-sm ring-1 ring-rcprimary-400 ring-inset focus-within:ring-2 focus-within:ring-rcprimary-400 focus-within:ring-inset sm:max-w-md">
            <span className="inline-flex items-center rounded-l-md border border-gray-300 border-r-0 px-3 text-gray-500 sm:text-sm">
              https://
            </span>
            {children}
          </div>
        </div>
      )}

      {!website && file && children}
      {!(website || file) && (
        <div className="relative mt-1 flex rounded-md shadow-sm md:mt-2">
          {(type === 'email' || type === 'password') && (
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              {type === 'email' ? (
                <EnvelopeIcon className={iconClasses} aria-hidden="true" />
              ) : (
                <LockClosedIcon className={iconClasses} aria-hidden="true" />
              )}
            </div>
          )}

          {children}
        </div>
      )}

      <FormErrors name={name} errors={errors} />
    </div>
  );
}

FieldContainer.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  style: PropTypes.object,
  hideLabel: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string,
  website: PropTypes.bool,
  file: PropTypes.bool,
};

export default FieldContainer;
