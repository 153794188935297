import { gql } from '@apollo/client';
import { AccountListInfoShared } from '@retainerclub/shared-api';

export default gql`
  query accounts {
    accounts {
      ...AccountListInfoShared
    }
  }
  ${AccountListInfoShared}
`;
