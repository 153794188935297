import { Button, timestampFormatter } from '@retainerclub/common';
import ShowStatus from '@retainerclub/common/src/shared/ShowElements/ShowStatus/ShowStatus';
import { CustomerCard } from '@retainerclub/customer-shared';
import { useAccount } from '@retainerclub/hooks';
import { useState } from 'react';
import FamilyUsersModal from './FamilyUsersModal';

function FamilyUsers() {
  const { users, accountRefetch } = useAccount();
  const [open, setOpen] = useState(false);

  function handleCloseModal() {
    setOpen(false);
    accountRefetch();
  }

  return (
    <>
      <CustomerCard
        title="Account Holders"
        action={
          <Button onClick={() => setOpen(true)} name="New Account Holder" add />
        }
      >
        <ul className="divide-y divide-gray-100">
          {users.map((user) => (
            <li key={user.id} className="flex justify-between gap-x-6 py-5">
              <div className="flex min-w-0 gap-x-4">
                <img
                  className="size-12 flex-none rounded-full bg-gray-50"
                  src={
                    user.imageUrl ||
                    'https://cdn.retainerclub.com/images/defaults/default-user.jpeg'
                  }
                  alt=""
                />
                <div className="min-w-0 flex-auto">
                  <p className="font-semibold text-gray-900 text-sm leading-6">
                    {user.firstName} {user.lastName}
                  </p>
                  <p className="mt-1 flex text-gray-500 text-xs leading-5">
                    {user.email}
                  </p>
                </div>
              </div>
              <div className="flex shrink-0 items-center gap-x-6">
                <div className="hidden sm:flex sm:flex-col sm:items-end">
                  <p className="text-gray-900 text-sm leading-6">
                    <ShowStatus state={user.state} />
                  </p>

                  <p className="mt-1 text-gray-500 text-xs leading-5">
                    Joined {timestampFormatter(user.createdAt, false)}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </CustomerCard>
      <FamilyUsersModal
        open={open}
        handleCloseModal={() => handleCloseModal()}
      />
    </>
  );
}

export default FamilyUsers;
