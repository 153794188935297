import PropTypes from 'prop-types';
import { Input } from '../Inputs';
import { CountrySelect, RegionSelect } from '../Selects';

function AddressGroup({
  requiredFields = false,
  usOnly = false,
  auOnly = false,
  shipping = false,
}) {
  return (
    <div className="sm:col-span-6">
      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 md:col-span-2">
        <div className="sm:col-span-3">
          <CountrySelect
            name={`${shipping ? 'partnerShippingAddress' : 'address'}.country`}
            required
            usOnly={usOnly}
            auOnly={auOnly}
          />
        </div>
        <div className="sm:col-span-3">&nbsp;</div>
        <div className="sm:col-span-3">
          <Input
            name={`${shipping ? 'partnerShippingAddress' : 'address'}.address`}
            label="Address"
            required={requiredFields}
          />
        </div>
        <div className="sm:col-span-3">
          <Input
            name={`${shipping ? 'partnerShippingAddress' : 'address'}.address2`}
            label="Address Line 2"
          />
        </div>
        <div className="sm:col-span-2">
          <Input
            name={`${shipping ? 'partnerShippingAddress' : 'address'}.city`}
            label="City"
            required={requiredFields}
          />
        </div>
        <div className="sm:col-span-2">
          <RegionSelect
            name={`${shipping ? 'partnerShippingAddress' : 'address'}.region`}
            countryFieldName={`${shipping ? 'partnerShippingAddress' : 'address'}.country`}
          />
        </div>
        <div className="sm:col-span-2">
          <Input
            name={`${shipping ? 'partnerShippingAddress' : 'address'}.postalCode`}
            label="Postal Code"
            required={requiredFields}
          />
        </div>
      </div>
    </div>
  );
}

AddressGroup.propTypes = {
  requiredFields: PropTypes.bool,
  usOnly: PropTypes.bool,
  auOnly: PropTypes.bool,
  shipping: PropTypes.bool,
};

export default AddressGroup;
