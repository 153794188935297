import { gql } from '@apollo/client';

const ProductionOrderListInfo = gql`
  fragment ProductionOrderListInfo on ProductionOrder {
    id
    state
    hexCode
    slug
    sentToExternalManufacturer
    patient {
      id
      slug
      firstName
      lastName
    }
    partnerLocation {
      id
      name
      slug
    }
    productionOrderItemsAttributes {
      id
      name
      quantity
      trackingNumber
      trackingUrl
      labelUrl
      manufacturerExternalId
      shippingCarrier {
        id
        name
      }
      product {
        id
        name
      }
      patient {
        id
        firstName
        lastName
        slug
      }
    }
    createdAt
    updatedAt
  }
`;

export default ProductionOrderListInfo;
