import { useAccount } from '@retainerclub/hooks';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FamilyPatientModal from '../../Family/FamilyPatients/FamilyPatientModal';
import RetainerWizardModal from '../RetainerWizard/RetainerWizardModal';
import DashboardPatientAddAFamilyMember from './DashboardPatientAddAFamilyMember';
import DashboardPatientCard from './DashboardPatientCard';

function DashboardPatients() {
  const { patients, setCurrentPatient, accountRefetch } = useAccount();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [modalPatient, setModalPatient] = useState(null);
  const [openRetainerWizard, setOpenRetainerWizard] = useState(false);

  function handleShop(patient) {
    setCurrentPatient(patient);
    navigate('/shop');
  }

  function handleOpenModal(p) {
    setModalPatient(p);
    setOpen(true);
  }

  function handleCloseModal() {
    setOpen(false);
    setModalPatient(null);

    accountRefetch();
  }

  function handleOpenRetainerWizard(p) {
    setModalPatient(p);
    setOpenRetainerWizard(true);
  }

  function handleCloseRetainerWizard() {
    setOpenRetainerWizard(false);
    setModalPatient(null);

    accountRefetch();
  }

  return (
    <>
      <div className="mb-4 flex flex-col flex-wrap justify-start gap-y-4 sm:flex-row">
        {[...patients]
          .sort((a, b) => a.firstName.localeCompare(b.firstName))
          .map((patient) => (
            <DashboardPatientCard
              key={patient.id}
              patient={patient}
              handleShop={(p) => handleShop(p)}
              handleOpenModal={(p) => handleOpenModal(p)}
              handleOpenRetainerWizard={(p) => handleOpenRetainerWizard(p)}
            />
          ))}

        <DashboardPatientAddAFamilyMember
          handleOpenModal={() => handleOpenModal(null)}
        />
      </div>
      <FamilyPatientModal
        open={open}
        handleCloseModal={() => handleCloseModal()}
        currentPatient={modalPatient}
      />
      <RetainerWizardModal
        open={openRetainerWizard}
        handleCloseModal={() => handleCloseRetainerWizard()}
        currentPatient={modalPatient}
      />
    </>
  );
}

export default DashboardPatients;
