import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { UPDATE_PARTNER_LOC_CASE_SETTING } from '@retainerclub/shared-api';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { BaseLoader, ErrorMessage, SubmitButton } from '../../../../../shared';
import CaseSettingsFormFields from '../../../../CaseSettings/CaseSettingsFormFields';
import { initialValues, schema } from './helpers';

function CaseSettingsFormInner({ partnerLocation }) {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [mutationErrors, setMutationErrors] = useState(null);

  const app = import.meta.env.VITE_APP_NAME;

  const [update, { error, loading }] = useMutation(
    UPDATE_PARTNER_LOC_CASE_SETTING,
  );

  const {
    register,
    handleSubmit,
    formState: { isLoading, isSubmitting, isValid },
    formState,
    getValues,
    setValue,
    control,
    ...rest
  } = useForm({
    resolver: yupResolver(schema()),
    defaultValues: initialValues({ record: partnerLocation }),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  async function submitRecord(values) {
    try {
      const params = {
        variables: {
          input: {
            input: { ...values },
            clientMutationId: crypto.randomUUID(),
          },
        },
      };

      const { data } = await update(params);

      const mErrors = data.updatePartnerLocationCaseSetting.errorMessages;

      if (mErrors.length > 0) {
        setMutationErrors(mErrors);
      } else {
        let url = '/partners/locations/';
        if (app.includes('partner')) {
          url = '/locations/';
        }

        navigate(`${url}${slug}`, {
          state: {
            showToast: true,
            title: 'Success',
            message: 'Record updated successfully',
          },
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  if (loading) {
    return <BaseLoader />;
  }

  return (
    <FormProvider
      {...rest}
      control={control}
      register={register}
      handleSubmit={handleSubmit}
      formState={formState}
      getValues={getValues}
      setValue={setValue}
    >
      <form onSubmit={handleSubmit(submitRecord)} noValidate>
        {app.includes('partner') && (
          <div className="relative mb-4 flex flex-col items-start rounded-md bg-white/80 p-4 shadow sm:flex-row sm:justify-between">
            <div
              className="-z-10 absolute inset-0 overflow-hidden"
              aria-hidden="true"
            >
              <div className="-mt-16 xl:-ml-80 absolute top-full left-16 transform-gpu opacity-50 blur-3xl xl:left-1/2">
                <div
                  className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#118a58] to-[#edfcf3]"
                  style={{
                    clipPath:
                      'polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)',
                  }}
                />
              </div>
              <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
            </div>

            <h2 className="mr-4 font-bold text-black/90 text-xl leading-6">
              Default Case Settings
            </h2>

            <p className="mt-1 text-black/50 text-sm">
              Default settings used to create new cases.
            </p>
          </div>
        )}
        <div className="mb-12 grid grid-cols-1 gap-x-8 md:grid-cols-4">
          <div
            className={`rounded-md bg-white/80 p-4 shadow ${
              app.includes('admin') ? 'md:col-span-3' : 'md:col-span-4'
            }`}
          >
            <div className="grid grid-cols-1 gap-x-8 gap-y-4 sm:grid-cols-6">
              <div className="col-span-6 grid grid-cols-1 gap-4 sm:grid-cols-6 md:col-span-6">
                <CaseSettingsFormFields />
              </div>
            </div>
            <div>
              {error && (
                <div className="mt-6 sm:col-span-6">
                  <ErrorMessage error={error} />
                </div>
              )}

              {mutationErrors && (
                <div className="mt-6 sm:col-span-6">
                  <ErrorMessage errorMessages={mutationErrors} />
                </div>
              )}
              <div className="mt-6 flex items-center justify-end gap-x-6 sm:col-span-6">
                <SubmitButton
                  isLoading={isLoading || loading}
                  isSubmitting={isSubmitting}
                  isValid={isValid}
                  className="w-1/3"
                  label="Update"
                  full={false}
                  notRounded
                />
              </div>
            </div>
          </div>
          {app.includes('admin') && (
            <div className="hidden sm:block">
              <h2 className="font-bold text-black text-xl leading-6">
                Default Case Settings
              </h2>

              <p className="mt-1 text-black/40 text-sm">
                Default settings used to create new cases.
              </p>
            </div>
          )}
        </div>
      </form>
    </FormProvider>
  );
}

CaseSettingsFormInner.propTypes = {
  partnerLocation: PropTypes.object.isRequired,
};

export default CaseSettingsFormInner;
