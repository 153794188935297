import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CREATE_ACCOUNT_ADDRESS,
  UPDATE_ACCOUNT_ADDRESS,
} from '@retainerclub/shared-api';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import SubmitButton from '../../../FormElements/Buttons/SubmitButton';
import BaseLoader from '../../../Loader/BaseLoader';
import AddressFields from './AddressFields';
import { initialValues, schema } from './helpers';

function AccountAddressesModalWrapper({
  accountAddress = null,
  handleCloseModal,
  children,
  accountId = null,
}) {
  const [mutationErrors, setMutationErrors] = useState(null);

  const [update, { error, loading }] = useMutation(UPDATE_ACCOUNT_ADDRESS);
  const [create, { error: createError, loading: createLoading }] = useMutation(
    CREATE_ACCOUNT_ADDRESS,
  );

  const {
    register,
    handleSubmit,
    formState: { isLoading, isSubmitting, isValid },
    formState,
    getValues,
    setValue,
    control,
    ...rest
  } = useForm({
    resolver: yupResolver(schema()),
    defaultValues: initialValues({ record: accountAddress, accountId }),
    values: initialValues({ record: accountAddress, accountId }),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  async function submitRecord(values) {
    try {
      const params = {
        variables: {
          input: {
            input: { ...values },
            clientMutationId: crypto.randomUUID(),
          },
        },
      };

      if (accountAddress) {
        const { data } = await update(params);

        const mErrors = data.updateAccountAddress.errorMessages;

        if (mErrors.length > 0) {
          setMutationErrors(mErrors);
        } else {
          setMutationErrors(null);
          handleCloseModal();
        }
      } else {
        const { data } = await create(params);

        const mErrors = data.createAccountAddress.errorMessages;

        if (mErrors.length > 0) {
          setMutationErrors(mErrors);
        } else {
          setMutationErrors(null);
          handleCloseModal();
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  if (loading || createLoading) {
    return <BaseLoader title="Address" />;
  }

  return (
    <FormProvider
      {...rest}
      control={control}
      register={register}
      handleSubmit={handleSubmit}
      formState={formState}
      getValues={getValues}
      setValue={setValue}
    >
      <form onSubmit={handleSubmit(submitRecord)} noValidate>
        <div>
          <div>
            <AddressFields />
            {children}

            <div className="col-span-full">
              {error && (
                <div className="col-span-full mt-4">
                  <ErrorMessage error={error} />
                </div>
              )}

              {createError && (
                <div className="col-span-full mt-4">
                  <ErrorMessage error={createError} />
                </div>
              )}

              {mutationErrors && (
                <div className="col-span-full mt-4">
                  <ErrorMessage errorMessages={mutationErrors} />
                </div>
              )}
              <div className="col-span-full mt-4 flex items-center justify-end gap-x-4">
                <SubmitButton
                  isLoading={isLoading || loading || createLoading}
                  isSubmitting={isSubmitting}
                  isValid={isValid}
                  label={accountAddress ? 'Update' : 'Create'}
                  full
                  notRounded
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}

AccountAddressesModalWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  accountAddress: PropTypes.object,
  accountId: PropTypes.string,
};

export default AccountAddressesModalWrapper;
