import { PlusIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';

function DashboardPatientAddAFamilyMember({ handleOpenModal }) {
  return (
    <div className="basis-1 sm:basis-1/2 md:basis-1/3 lg:basis-1/4">
      <div className="group relative m-2 flex h-full cursor-pointer flex-col items-center justify-center rounded-lg border-4 border-rcgray-200 border-dashed bg-rcprimary-400/90 p-4 text-center shadow-lg hover:border-white hover:bg-rcprimary-400">
        <button type="button" onClick={() => handleOpenModal(null)}>
          <span aria-hidden="true" className="absolute inset-0" />
          <PlusIcon
            className="mx-auto size-24 text-rcgray-200 drop-shadow-xl group-hover:text-white"
            aria-hidden="true"
          />
          <h3 className="font-bold text-2xl text-rcgray-200 drop-shadow-xl group-hover:text-white">
            Add A New Family Member
          </h3>
        </button>
      </div>
    </div>
  );
}

DashboardPatientAddAFamilyMember.propTypes = {
  handleOpenModal: PropTypes.func.isRequired,
};

export default DashboardPatientAddAFamilyMember;
