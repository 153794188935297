import PropTypes from 'prop-types';

function ShowTableStandardRow({ children, light = false }) {
  return (
    <tr
      className={`divide-x ${
        light
          ? 'divide-black/0 bg-rcgray-200/40 text-black odd:bg-white/80'
          : 'divide-black/20 bg-rcgray-200/50 text-black'
      }`}
    >
      {children}
    </tr>
  );
}

ShowTableStandardRow.propTypes = {
  children: PropTypes.node.isRequired,
  light: PropTypes.bool,
};

export default ShowTableStandardRow;
