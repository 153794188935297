import { useApp } from '@retainerclub/hooks';
import BasicProfile from './BasicProfile/BasicProfile';
import UpdatePassword from './UpdatePassword/UpdatePassword';

function Profile() {
  const { isAdmin, isCustomer } = useApp();

  return (
    <div
      className={`mx-auto ${
        isAdmin
          ? 'max-w-6xl'
          : isCustomer
            ? 'mt-4 w-full border bg-rcgray-50'
            : 'max-w-full bg-white/80'
      } space-y-8 rounded-lg p-8 shadow-lg`}
    >
      <BasicProfile />
      <UpdatePassword />
    </div>
  );
}

export default Profile;
