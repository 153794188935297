import PropTypes from 'prop-types';

function CustomerCard({
  children,
  className = '',
  title = null,
  description = null,
  wrapperClassName = '',
  action = null,
}) {
  return (
    <section
      aria-labelledby="applicant-information-title"
      className={wrapperClassName}
    >
      <div className={`p-4 ${className}`}>
        {title && (
          <div className="mb-4 flex flex-wrap items-center justify-between border-black/20 border-b pb-2 sm:flex-nowrap">
            <div>
              <h2 className=" font-bold text-gray-900 text-xl tracking-tight sm:text-2xl">
                {title}
              </h2>
              {description && (
                <p className="text-base text-black/60">{description}</p>
              )}
            </div>
            {action && <div>{action}</div>}
          </div>
        )}

        {children}
      </div>
    </section>
  );
}

CustomerCard.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  wrapperClassName: PropTypes.string,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
  action: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
};

export default CustomerCard;
