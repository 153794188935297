import { gql } from '@apollo/client';

const ProductInfo = gql`
  fragment ProductInfo on Product {
    id
    name
    internalName
    price
    wholesalePrice
    arches
    enableArches
    renewalPrice
    description
    sku
    slug
    hubspotId
    externalId
    defaultSubscriptionRetainer
    productCategories {
      id
      name
      slug
    }
    productCategoryIds
    adminLocationIds
    active
    distributeInitialEvenly
    bypassInitialPeriod
    allowAnnually
    allowMonthly
    target
    unitsPerInterval
    initialUnits
    featuredImage
    productRenewalIntervalId
    productRenewalInterval {
      id
      name
    }
    productFeaturesAttributes {
      id
      text
    }
    productImages {
      id
      imageUrl
    }
    storeIds
    stores {
      id
      name
    }
    productType
    allowMarkup
    requiresScan
    planSubscriptionId
    planSubscription {
      id
      name
    }
    planProductsAttributes {
      id
      assignedProductId
      overridePrice
      price
    }
    adminLocationIds
    adminLocations {
      id
      name
    }
    createdAt
    updatedAt
  }
`;

export default ProductInfo;
