import { useQuery } from '@apollo/client';
import { CheckIcon } from '@heroicons/react/20/solid';
import { useCart, useLocale } from '@retainerclub/hooks';
import { GET_SUBSCRIPTION_PRODUCTS } from '@retainerclub/shared-api';
import PropTypes from 'prop-types';
import { useState } from 'react';

function RetainerWizardNoBankSubscriptions({ patient, handleCloseModal }) {
  const { locale } = useLocale();
  const [subscriptions, setSubscriptions] = useState([]);

  const { loading } = useQuery(GET_SUBSCRIPTION_PRODUCTS, {
    onCompleted: (d) => {
      const silverSub = [...d.subscriptionProducts].sort(
        (a, b) => a.price - b.price,
      )[0];

      const remainingSubs = [...d.subscriptionProducts]
        .sort((a, b) => b.price - a.price)
        .slice(0, 2)
        .sort((a, b) => a.price - b.price);

      const allSubs = [silverSub, ...remainingSubs];

      setSubscriptions(allSubs);
    },
  });

  const {
    updateCart,
    cartId,
    cart: { cartItemsAttributes },
  } = useCart();

  function handleAddOrRemoveToCart(productId) {
    updateCart({
      id: cartId,
      patientId: patient.id,
      cartItemsAttributes: [
        ...cartItemsAttributes.map((item) => ({
          productId: item.product.id,
          quantity: item.quantity,
          patientId: item.patientId,
          id: item.id,
        })),
        {
          productId,
          quantity: 1,
          patientId: patient.id,
        },
      ],
    });

    handleCloseModal();
  }

  if (loading || subscriptions.length === 0) {
    return null;
  }

  return subscriptions.map((subscription, i) => (
    <div
      key={subscription.id}
      className={`${
        i === 1
          ? 'z-10 bg-white shadow-xl ring-1 ring-gray-900/10'
          : 'bg-rcprimary-900/80 ring-1 ring-white/10 lg:bg-transparent lg:pb-14 lg:ring-0'
      } relative rounded-2xl`}
    >
      <div className="p-8 lg:pt-12 xl:p-10 xl:pt-14">
        <h3
          className={`${i === 1 ? 'text-rcgray-900' : 'text-white'} font-semibold text-sm leading-6`}
        >
          {subscription.product.name}
        </h3>
        <div className="flex flex-col gap-6 sm:flex-row sm:items-end sm:justify-between lg:flex-col lg:items-stretch">
          <div className="mt-2 flex items-center gap-x-4">
            <p
              className={`${i === 1 ? 'text-rcgray-900' : 'text-white'} font-bold text-4xl tracking-tight`}
            >
              {(subscription.price / 100).toLocaleString(locale, {
                style: 'currency',
                currency: 'USD',
              })}
            </p>
            <div className="text-sm leading-5">
              <p className={i === 1 ? 'text-gray-500' : 'text-gray-400'}>
                Annually
              </p>
            </div>
          </div>
          <button
            type="button"
            onClick={() => handleAddOrRemoveToCart(subscription.product.id)}
            className={`${
              i === 1
                ? 'bg-rcprimary-400 shadow-sm hover:bg-rcprimary-500 focus-visible:outline-rcprimary-400'
                : 'bg-white/10 hover:bg-white/20 focus-visible:outline-white'
            } rounded-md px-3 py-2 text-center font-semibold text-sm text-white leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2`}
          >
            Add to Cart
          </button>
        </div>
        <div className="mt-8 flow-root sm:mt-10">
          <div>
            <p
              className={`mb-4 text-xs leading-5 ${
                i === 1 ? 'text-gray-600' : 'text-white'
              }`}
            >
              {subscription.product.description}
            </p>
          </div>
          <ul
            className={`${
              i === 1
                ? 'divide-gray-900/5 border-gray-900/5 text-gray-600'
                : 'divide-white/5 border-white/5 text-white'
            } -my-2 divide-y border-t text-sm leading-6 lg:border-t-0`}
          >
            {subscription.product.productFeatures.map((feature) => (
              <li key={feature.id} className="flex gap-x-2 py-2">
                <CheckIcon
                  className={`${
                    i === 1 ? 'text-rcprimary-400' : 'text-gray-500'
                  } h-6 w-5 flex-none`}
                  aria-hidden="true"
                />
                {feature.text}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  ));
}

RetainerWizardNoBankSubscriptions.propTypes = {
  patient: PropTypes.object.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

export default RetainerWizardNoBankSubscriptions;
