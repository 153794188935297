import { useAccount } from '@retainerclub/hooks';
import ShopPatient from './ShopPatient';

function ShopFilters() {
  const { patients } = useAccount();

  return patients?.length > 1 ? (
    <div className="mx-auto mb-4 flex max-w-7xl justify-center px-4 sm:justify-start sm:px-6 lg:px-8">
      <div className="py-2 sm:py-4">
        <ShopPatient />
      </div>
    </div>
  ) : null;
}

export default ShopFilters;
