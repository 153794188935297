import { NavLink } from 'react-router-dom';

function MobileNavAuthed() {
  return (
    <>
      <NavLink
        to="/"
        className="-mx-3 block rounded-lg px-3 py-2 font-semibold text-base text-gray-900 leading-7 hover:bg-gray-50"
      >
        Dashboard
      </NavLink>
      <NavLink
        to="/shop"
        className="-mx-3 block rounded-lg px-3 py-2 font-semibold text-base text-gray-900 leading-7 hover:bg-gray-50"
      >
        Shop
      </NavLink>
      <NavLink
        to="/my-orders"
        className="-mx-3 block rounded-lg px-3 py-2 font-semibold text-base text-gray-900 leading-7 hover:bg-gray-50"
      >
        Orders
      </NavLink>
    </>
  );
}

export default MobileNavAuthed;
