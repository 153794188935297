import { Outlet } from 'react-router-dom';
import DashboardPartner from '../../views/Dashboard/DashboardPartner';

function DashboardLayout() {
  return (
    <div className="relative mb-24">
      <div className="relative col-span-full mx-auto flex max-w-7xl flex-col gap-y-4 pt-4">
        <Outlet />
        <DashboardPartner />
      </div>
    </div>
  );
}

export default DashboardLayout;
