import { useQuery } from '@apollo/client';
import { LoadingOrError } from '@retainerclub/common';
import { GET_PRICE_BOOK_PRODUCTS } from '@retainerclub/customer-api';
import { useAccount } from '@retainerclub/hooks';
import { useState } from 'react';
import ShopFilters from './ShopFilters';
import ShopProducts from './ShopProducts';

function Shop() {
  const { currentPatient } = useAccount();

  const [filteredProducts, setFilteredProducts] = useState([]);

  const { error, loading } = useQuery(GET_PRICE_BOOK_PRODUCTS, {
    onCompleted: (data) => {
      const { priceBookProducts: pBP } = data;

      const allRetainers = pBP.filter((p) =>
        p.product.productCategories.some((c) => c.name === 'Retainers'),
      );

      const retainers = allRetainers.filter((r) =>
        currentPatient.hasPlan
          ? r.product.defaultSubscriptionRetainer === true
          : r.product.defaultSubscriptionRetainer === false,
      );

      setFilteredProducts([
        ...pBP.filter((p) => p.product.productType === 'plan'),
        ...retainers,
        ...pBP
          .filter(
            (p) =>
              p.product.productType !== 'plan' &&
              p.product.productCategories.some((c) => c.name !== 'Retainers'),
          )
          .sort((a, b) => a.product.name.localeCompare(b.product.name)),
      ]);
    },
  });

  if (loading || error) {
    return <LoadingOrError loading={loading} error={error} />;
  }

  return (
    <div className=" bg-white">
      <main>
        <div className="mx-auto max-w-7xl px-4 py-10 text-left sm:px-6 lg:px-8">
          <h1 className="font-bold text-4xl text-black tracking-tight">
            Shop for {currentPatient?.firstName}
          </h1>
          <p className="mt-4 max-w-xl text-base text-rcprimary-500">
            Shop the latest products offered by your provider
          </p>
        </div>

        <ShopFilters />

        <ShopProducts priceBookProducts={filteredProducts} />
      </main>
    </div>
  );
}

export default Shop;
