import PropTypes from 'prop-types';
import { timestampFormatter } from '../../../transforms';
import Button from '../../Button/Button';
import Card from '../../Card/Card';
import RcLink from '../../RcLink/RcLink';
import ShowStatus from '../ShowStatus/ShowStatus';
import ShowTableStandard from '../ShowTableStandard/ShowTableStandard';
import ShowTableStandardRow from '../ShowTableStandard/ShowTableStandardRow';

function ShowReferrals({ referrals = [], title = 'Referrals' }) {
  return (
    <Card className="p-4" heading={title}>
      <ShowTableStandard
        tHead={
          <>
            <th scope="col" className="p-4">
              Referral
            </th>
            <th scope="col" className="hidden p-4 sm:table-cell">
              Source
            </th>
            <th scope="col" className="hidden p-4 sm:table-cell">
              Status
            </th>
            <th scope="col" className="hidden p-4 sm:table-cell">
              Sent At
            </th>
            <th scope="col" className="hidden p-4 sm:table-cell">
              Activated At
            </th>
            <th scope="col" className="hidden p-4 sm:table-cell">
              Created At
            </th>
            <th scope="col" className="p-4 text-right">
              Actions
            </th>
          </>
        }
        tBody={referrals.map((referral) => (
          <ShowTableStandardRow key={referral.id}>
            <td className="max-w-0 p-4 uppercase">
              <RcLink to={`/customers/referrals/${referral.slug}`}>
                {referral.hexCode}
              </RcLink>
            </td>
            <td className="hidden p-4 sm:table-cell">
              {referral.source === 'qr_code'
                ? 'QR Code'
                : referral.source.replace(/_/g, ' ')}
            </td>
            <td className="hidden p-4 sm:table-cell">
              <ShowStatus state={referral.state} />
            </td>
            <td className="hidden p-4 sm:table-cell">
              {referral.sentAt ? timestampFormatter(referral.sentAt) : 'N/A'}
            </td>
            <td className="hidden p-4 sm:table-cell">
              {referral.consumedAt
                ? timestampFormatter(referral.consumedAt)
                : 'N/A'}
            </td>
            <td className="hidden p-4 sm:table-cell">
              {referral.createdAt
                ? timestampFormatter(referral.createdAt)
                : 'N/A'}
            </td>
            <td className="flex justify-end gap-x-4 p-4 text-right">
              <Button
                to={`/customers/referrals/${referral.slug}`}
                show
                size="xs"
              />
              <Button
                to={`/customers/referrals/${referral.slug}/edit`}
                edit
                size="xs"
              />
            </td>
          </ShowTableStandardRow>
        ))}
      />
    </Card>
  );
}

ShowReferrals.propTypes = {
  referrals: PropTypes.array,
  title: PropTypes.string,
};

export default ShowReferrals;
