import PropTypes from 'prop-types';

function EotCheckbox({ setCheckboxChange, checkboxChange }) {
  return (
    <div className="relative flex gap-x-3">
      <div className="flex h-6 items-center">
        <input
          type="checkbox"
          checked={checkboxChange}
          onChange={() => setCheckboxChange(!checkboxChange)}
          className="size-5 rounded border-gray-300 border-rcgray-600 text-rcprimary-400 focus:ring-rcprimary-400 disabled:cursor-not-allowed disabled:bg-rcgray-600 disabled:text-rcgray-600 disabled:ring-rcgray-700"
        />
      </div>
      <div className="text-sm leading-6">
        <label htmlFor="comments" className="font-medium text-black/90 text-md">
          Are these End of Treatment Retainer(s)
          <span className="text-red-400">*</span>
        </label>
        <p className="text-black/60 text-xs">
          Select to choose these as the initial end of treatment retainer(s) for
          the patient.
        </p>
      </div>
    </div>
  );
}

EotCheckbox.propTypes = {
  setCheckboxChange: PropTypes.func.isRequired,
  checkboxChange: PropTypes.bool.isRequired,
};

export default EotCheckbox;
