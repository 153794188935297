import {
  InformationCircleIcon,
  TruckIcon,
  UserIcon,
  UserPlusIcon,
} from '@heroicons/react/20/solid';

export const profileNavigation = [
  { id: 1, name: 'Profile', to: '/profile', icon: UserIcon },
  {
    id: 2,
    name: 'Support',
    to: 'https://www.v2.retainerclub.com/support',
    icon: InformationCircleIcon,
  },
];

export const mobileProfileNavigation = [
  { id: 1, name: 'Profile', to: '/profile', icon: UserIcon },
  { id: 2, name: 'Family', to: '/my-family', icon: UserPlusIcon },
  { id: 3, name: 'Address Book', to: '/addresses', icon: TruckIcon },
  {
    id: 3,
    name: 'Support',
    to: 'https://www.v2.retainerclub.com/support',
    icon: InformationCircleIcon,
  },
];
