import { useLocale } from '@retainerclub/hooks';
import PropTypes from 'prop-types';
import GeneralSectionItem from '../ProductShowGeneralSection/GeneralSectionItem';
import DetailsSection from './DetailsSection';
import SettingsItem from './SettingsItem';

const NONE_SELECTED = 'None Selected';

function ProductShowDetails(props) {
  const { product } = props;

  const { locale } = useLocale();

  return (
    <div className="divide-y divide-rcgray-400 border-rcgray-400 border-t">
      <DetailsSection name="Price Details">
        {(product.productType === 'product' ||
          product.productType === 'plan') && (
          <div className="mt-4">
            <dl className="divide-y divide-rcgray-500">
              <GeneralSectionItem
                name="Retail Price"
                details={(product.price / 100).toLocaleString(locale, {
                  style: 'currency',
                  currency: 'USD',
                })}
              />
              <GeneralSectionItem
                name="RCs Portion"
                details={(product.wholesalePrice / 100).toLocaleString(locale, {
                  style: 'currency',
                  currency: 'USD',
                })}
              />
            </dl>
          </div>
        )}

        {product.productType === 'subscription' && (
          <div className="mt-4">
            <dl className="divide-y divide-rcgray-500">
              <GeneralSectionItem
                name="Initial Retail Price"
                details={(product.price / 100).toLocaleString(locale, {
                  style: 'currency',
                  currency: 'USD',
                })}
              />
              <GeneralSectionItem
                name="Renewal Retail Price"
                details={(product.renewalPrice / 100).toLocaleString(locale, {
                  style: 'currency',
                  currency: 'USD',
                })}
              />
              <GeneralSectionItem
                name="RCs Portion"
                details={(product.wholesalePrice / 100).toLocaleString(locale, {
                  style: 'currency',
                  currency: 'USD',
                })}
              />
            </dl>
          </div>
        )}
      </DetailsSection>

      <DetailsSection name="Assignments">
        <div className="mt-4">
          <dl className="divide-y divide-rcgray-900">
            <GeneralSectionItem
              name="Target Audience"
              details={product.target}
            />
            <GeneralSectionItem
              name="Assigned Admin Locations"
              details={
                product.adminLocations.length > 0
                  ? product.adminLocations.map((store) => store.name).join()
                  : NONE_SELECTED
              }
            />
            <GeneralSectionItem
              name="Assigned Stores"
              details={
                product.stores.length > 0
                  ? product.stores.map((store) => store.name).join()
                  : NONE_SELECTED
              }
            />

            {product.enableArches && (
              <GeneralSectionItem name="Arches" details={product.arches} />
            )}
          </dl>
        </div>
      </DetailsSection>

      <DetailsSection name="Settings">
        <ul className="grid grid-cols-1 text-black text-sm sm:grid-cols-2 sm:gap-1">
          <SettingsItem item={product.active} name="Active" />
          <SettingsItem item={product.allowMarkup} name="Allow Markup" />
          <SettingsItem
            item={product.requiresScan}
            name="Requires Customer Scan"
          />
          <SettingsItem
            item={product.enableArches}
            name="Allow Arch Settings"
          />
        </ul>
      </DetailsSection>
      <DetailsSection name="Connection Data">
        <div className="mt-4">
          <dl className="divide-y divide-rcgray-900">
            <GeneralSectionItem
              name="Hubspot ID"
              details={product.hubspotId ? product.hubspotId : 'Not Available'}
            />
            <GeneralSectionItem
              name="Stripe ID"
              details={
                product.externalId ? product.externalId : 'Not Available'
              }
            />
          </dl>
        </div>
      </DetailsSection>
    </div>
  );
}

ProductShowDetails.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ProductShowDetails;
