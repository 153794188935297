import { useApolloClient, useMutation } from '@apollo/client';
import { UPDATE_CURRENT_USER, UPDATE_PASSWORD } from '@retainerclub/shared-api';
import {
  confirmResetPassword,
  fetchUserAttributes,
  getCurrentUser,
  resetPassword,
  signIn,
  signOut,
} from 'aws-amplify/auth';
import PropTypes from 'prop-types';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const AuthContext = createContext();

const CUSTOM_LANGUAGE = 'custom:language';
const CUSTOM_IMAGE = 'custom:imageUrl';

export function AuthProvider({ children }) {
  const [authLoading, setAuthLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [tempCredentials, setTempCredentials] = useState({});
  const [error, setError] = useState();
  const client = useApolloClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();
  const app = import.meta.env.VITE_APP_NAME;

  const [update] = useMutation(UPDATE_CURRENT_USER);
  const [updatePassword] = useMutation(UPDATE_PASSWORD);

  const updateCurrentUser = useCallback(
    async (bypassCache = false) => {
      try {
        const user = await fetchUserAttributes({
          bypassCache,
        });

        const userSecondData = await getCurrentUser();

        const navName = `${user.name.charAt().toUpperCase()}. ${
          user.family_name
        }`;

        const roleLevel = Number.parseInt(user['custom:roleLevel'], 10);

        const newCurrentUser = {
          username: userSecondData.username,
          navName,
          roleLevel,
          firstName: user.name,
          lastName: user.family_name,
          email: user.email,
          emailVerified: user.email_verified,
          phone: user.phone_number,
          phoneVerified: user.phone_number_verified,
          language: user[CUSTOM_LANGUAGE],
          imageUrl: user[CUSTOM_IMAGE],
        };

        setCurrentUser(newCurrentUser);

        const defaultLanguage = window.localStorage.getItem('defaultLanguage');

        i18n.changeLanguage(user[CUSTOM_LANGUAGE]);

        if (defaultLanguage !== user[CUSTOM_LANGUAGE]) {
          navigate('/profile');
        }
        setAuthLoading(false);
      } catch (err) {
        console.error(err);
        setCurrentUser(null);
        setAuthLoading(false);
      }
    },
    [i18n.changeLanguage, navigate],
  );

  const checkAuthStatus = useCallback(async () => {
    try {
      await getCurrentUser();
      updateCurrentUser();
    } catch (_err) {
      // console.error('User not authenticated:', err);
      setCurrentUser(null);
      setAuthLoading(false);
    }
  }, [updateCurrentUser]);

  const login = useCallback(
    async (values) => {
      const { email, phone, password, skipNavigate } = values;
      const from = location.state?.from?.pathname || '/';

      let username = email;

      if (app.includes('customer')) {
        username = phone;
      }

      try {
        const { nextStep } = await signIn({ username, password });

        if (
          nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'
        ) {
          setError(null);
          updateCurrentUser();
          setTempCredentials({ email, phone });
          navigate('/new-password');
        } else {
          setError(null);
          updateCurrentUser();

          const params = {
            variables: {
              input: {
                clientMutationId: crypto.randomUUID(),
              },
            },
          };

          await update(params);

          if (!skipNavigate) {
            navigate(from, { replace: true });
          }

          if (app.includes('partner')) {
            navigate('/customers/patients/needs-attention');
          }
        }
      } catch (err) {
        console.error('err', err);
        setError(err.message);
      }
    },
    [location.state?.from?.pathname, navigate, update, updateCurrentUser],
  );

  const newPassword = useCallback(
    async (values) => {
      const { password } = values;

      let klass = 'AdminUser';

      if (app.includes('customer')) {
        klass = 'User';
      }

      if (app.includes('partner')) {
        klass = 'PartnerUser';
      }

      if (app.includes('production')) {
        klass = 'ProductionUser';
      }

      try {
        const params = {
          variables: {
            input: {
              clientMutationId: crypto.randomUUID(),
              input: {
                password,
                klass,
                email: tempCredentials.email,
                phone: tempCredentials.phone,
              },
            },
          },
        };

        await updatePassword(params);

        setError(null);
        navigate('/');
      } catch (err) {
        console.error('err', err);
        setError(err.message);
      }
    },
    [navigate, tempCredentials.email, tempCredentials.phone, updatePassword],
  );

  const forgot = useCallback(
    async (values) => {
      const { email, phone } = values;

      let username = email;

      if (app.includes('customer')) {
        username = phone;
      }

      try {
        await resetPassword({ username });
        setError(null);
        setTempCredentials({ email, phone });
        navigate('/reset-password');
      } catch (err) {
        console.error('err', err);
        setError(err.message);
      }
    },
    [navigate],
  );

  const reset = useCallback(
    async (values) => {
      const { email, phone, token, password } = values;

      let username = email;

      if (app.includes('customer')) {
        username = phone;
      }

      try {
        await confirmResetPassword({
          username,
          confirmationCode: token,
          newPassword: password,
        });

        setError(null);
        setTempCredentials({});
        navigate('/signin');
      } catch (err) {
        console.error('err', err);
        setError(err.message);
      }
    },
    [navigate],
  );

  const signOutUser = useCallback(async () => {
    try {
      const defaultLanguage = window.localStorage.getItem('defaultLanguage');

      setCurrentUser(null);

      navigate('/signin');

      await signOut();
      client.resetStore();

      window.localStorage.removeItem('apollo-cache-persist');
      window.localStorage.setItem('defaultLanguage', defaultLanguage || 'en');
    } catch (err) {
      console.error(err);

      navigate('/signin');
    }
  }, [client, navigate]);

  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]);

  const value = useMemo(
    () => ({
      error,
      login,
      signOut: signOutUser,
      forgot,
      reset,
      tempCredentials,
      newPassword,
      currentUser,
      updateCurrentUser,
      authLoading,
    }),
    [
      currentUser,
      error,
      forgot,
      login,
      newPassword,
      reset,
      signOutUser,
      tempCredentials,
      updateCurrentUser,
      authLoading,
    ],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useAuth() {
  return useContext(AuthContext);
}
