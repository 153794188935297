import { gql } from '@apollo/client';

export default gql`
  query accountsChewy {
    accountsChewy {
      id
      attributes
    }
  }
`;
