import { useApp } from '@retainerclub/hooks';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Button from '../../Button/Button';
import Card from '../../Card/Card';
import AccountAddressModal from './AccountAddressModal/AccountAddressModal';
import DestroyShippingAddress from './AccountAddressModal/DestroyShippingAddress';

function ShowCustomerAddresses({
  accountId,
  accountAddresses = [],
  className = '',
  refetch = null,
}) {
  const [currentAddress, setCurrentAddress] = useState(null);
  const [open, setOpen] = useState(false);

  const { isCustomer } = useApp();

  function handleOpenModal(referral) {
    setCurrentAddress(referral);
    setOpen(true);
  }

  function handleCloseModal() {
    setCurrentAddress(null);
    setOpen(false);

    if (refetch) {
      refetch();
    }
  }

  return (
    <>
      <Card
        className={`p-4 md:mt-0 ${className} flex ${
          isCustomer ? 'min-h-[180px]' : 'h-full min-h-[180px]'
        } flex-col justify-between`}
        wrapperClassName="h-full"
        heading="Shipping Addresses"
        headingSize={isCustomer ? 'lg' : 'xl'}
      >
        <ul className="divide-y divide-black/20">
          {accountAddresses
            .filter((address) => address.isShipping)
            .map((address) => (
              <li
                key={address.id}
                className="flex items-center justify-between gap-x-6 py-5"
              >
                <div className="min-w-0 flex-auto">
                  <p className="font-base text-black/80 text-sm leading-5">
                    {address.address}
                    {address.address2 && (
                      <>
                        <br />
                        {address.address2}
                      </>
                    )}
                    <br />
                    <span>
                      {address.city}, {address.region} {address.postalCode}
                    </span>
                    <br />
                    <span>{address.country}</span>
                  </p>
                  {address.isDefault && (
                    <div className="mt-1 flex items-center gap-x-1">
                      <div className="flex-none rounded-full bg-green-400/10 p-1 text-green-400">
                        <div className="size-2 rounded-full bg-current" />
                      </div>
                      <p className="text-black/60 text-xs">default</p>
                    </div>
                  )}
                </div>

                <div className="flex gap-x-2">
                  {!address.isDefault && (
                    <DestroyShippingAddress id={address.id} refetch={refetch} />
                  )}
                  <Button
                    onClick={() => handleOpenModal(address)}
                    size="xs"
                    rounded
                    name="edit"
                    color="gray"
                    edit
                  >
                    Edit
                  </Button>
                </div>
              </li>
            ))}
        </ul>
        <div>
          <Button
            onClick={() => handleOpenModal(null)}
            name="Add New Address"
            add
            className="rounded-md"
            size="xs"
          />
        </div>
      </Card>
      <AccountAddressModal
        open={open}
        handleCloseModal={handleCloseModal}
        currentAddress={currentAddress}
        accountId={accountId}
      />
    </>
  );
}

ShowCustomerAddresses.propTypes = {
  accountAddresses: PropTypes.array,
  className: PropTypes.string,
  refetch: PropTypes.func,
  accountId: PropTypes.string.isRequired,
};

export default ShowCustomerAddresses;
