import { gql } from '@apollo/client';
import { ErrorMessageInfo, ScanFileInfoShared } from '../fragments';

export default gql`
  mutation updateScanFile($input: ScanFileUpdateInput!) {
    updateScanFile(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      scanFile {
        ...ScanFileInfoShared
      }
    }
  }
  ${ScanFileInfoShared}
  ${ErrorMessageInfo}
`;
