import { useApolloClient, useMutation } from '@apollo/client';
import { InboxArrowDownIcon, TrashIcon } from '@heroicons/react/20/solid';
import { ACTION_USER_RESEND_INVITE } from '@retainerclub/admin-api';
import { useApp, useToast } from '@retainerclub/hooks';
import { DESTROY_PARTNER_USER } from '@retainerclub/shared-api';
import PropTypes from 'prop-types';
import { SelectMenuItem } from '../../../../shared';

function PartnerUsersShowActions({ partnerUser }) {
  const client = useApolloClient();
  const { isAdmin } = useApp();
  const { setToast } = useToast();

  const [destroy] = useMutation(DESTROY_PARTNER_USER);

  async function handleDestroyPartnerUser() {
    try {
      const params = {
        variables: {
          input: {
            id: partnerUser.id,
            clientMutationId: crypto.randomUUID(),
          },
        },
      };

      await destroy(params);
    } catch (err) {
      console.error(err);
    }
  }

  async function resendInvite() {
    try {
      await client.mutate({
        mutation: ACTION_USER_RESEND_INVITE,
        variables: {
          input: {
            input: {
              id: partnerUser.id,
              klass: 'PartnerUser',
            },
            clientMutationId: crypto.randomUUID(),
          },
        },
      });

      setToast({
        show: true,
        title: 'Success',
        message: 'Invite has been resent to the Partner User',
        success: true,
      });
    } catch (error) {
      console.error(error);
      setToast({
        show: true,
        title: 'Error',
        message: "We couldn't resend the invite. Please try again later",
        success: false,
      });
    }
  }

  return (
    <>
      <div className="py-1">
        <SelectMenuItem
          onClick={() => handleDestroyPartnerUser()}
          icon={TrashIcon}
        >
          Delete User
        </SelectMenuItem>
      </div>

      {isAdmin && partnerUser?.state === 'pending' && (
        <div className="py-1">
          <SelectMenuItem
            onClick={() => resendInvite()}
            icon={InboxArrowDownIcon}
          >
            Resend Invite
          </SelectMenuItem>
        </div>
      )}
    </>
  );
}

PartnerUsersShowActions.propTypes = {
  partnerUser: PropTypes.object.isRequired,
};

export default PartnerUsersShowActions;
