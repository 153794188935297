import { gql } from '@apollo/client';

const PatientListInfoShared = gql`
  fragment PatientListInfoShared on Patient {
    id
    firstName
    lastName
    dob
    includeEot
    scanReady
    settingsOverride
    hasPlan
    slug
    legacyId
    orders {
      id
    }
    account {
      id
      users {
        id
        slug
        firstName
        lastName
        email
      }
    }
    createdAt
  }
`;

export default PatientListInfoShared;
