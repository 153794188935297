import { useFormValues, useReferral } from '@retainerclub/hooks';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

function OnboardingFormAccountSameCheckbox() {
  const [checkboxChange, setCheckboxChange] = useState(false);
  const { referral } = useReferral();

  const {
    patientAttributes: { firstName, lastName },
  } = useFormValues();
  const { setValue } = useFormContext();

  function handleCheckboxChange() {
    if (checkboxChange) {
      setValue('userAttributes.firstName', '');
      setValue('userAttributes.lastName', '');
    }

    if (!checkboxChange) {
      setValue('userAttributes.firstName', firstName);
      setValue('userAttributes.lastName', lastName);
    }

    setCheckboxChange(!checkboxChange);
  }

  return !referral ? (
    <div className="relative mb-2 flex gap-x-3 sm:col-span-6">
      <div className="flex h-6 items-center">
        <input
          type="checkbox"
          checked={checkboxChange}
          onChange={handleCheckboxChange}
          className="size-5 rounded border-rcgray-600 text-rcprimary-400 focus:ring-rcprimary-400 disabled:cursor-not-allowed disabled:bg-rcgray-600 disabled:text-rcgray-600 disabled:ring-rcgray-700"
        />
      </div>
      <div className="text-sm leading-6">
        <label htmlFor="comments" className="font-medium text-black/90 text-md">
          Account Holder is the same as the Patient?
        </label>
        <p className="text-black/60 text-xs">
          Select to copy the patient info to the account holder.
        </p>
      </div>
    </div>
  ) : null;
}

export default OnboardingFormAccountSameCheckbox;
