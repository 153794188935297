import { UserCircleIcon } from '@heroicons/react/24/solid';
import { useAuth } from '@retainerclub/hooks';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { mobileProfileNavigation } from '../helpers/navLinks';

function MobileNavProfile() {
  const { currentUser, signOut } = useAuth();
  const { t } = useTranslation('navigation');

  return currentUser ? (
    <>
      {mobileProfileNavigation.map((item) => (
        <NavLink
          key={item.id}
          to={item.to}
          className="group -mx-3 flex w-full items-center justify-start gap-x-2.5 rounded-lg px-3 py-2 font-semibold text-base text-gray-900 leading-7 hover:bg-gray-50"
        >
          {t(item.name)}
        </NavLink>
      ))}

      <button
        type="button"
        onClick={signOut}
        className="group -mx-3 flex w-full items-center justify-start gap-x-2.5 rounded-lg px-3 py-2 font-semibold text-base text-gray-900 leading-7 hover:bg-gray-50"
      >
        {t('Sign Out')}
      </button>
    </>
  ) : (
    <NavLink
      to="/signin"
      className="flex items-center font-semibold text-gray-900 text-sm leading-6"
    >
      <UserCircleIcon className="mr-2 size-8" aria-hidden="true" />
      <span className="font-normal text-base text-rcprimary-400">Sign In</span>
    </NavLink>
  );
}

export default MobileNavProfile;
