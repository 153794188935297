import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CREATE_CART_TRANSACTION,
  CREATE_ORDER,
} from '@retainerclub/customer-api';
import { useAccount, useAuth, useCart } from '@retainerclub/hooks';
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import CheckoutCart from './CheckoutCart/CheckoutCart';
import CheckoutForm from './CheckoutForm/CheckoutForm';
import { initialValues, responseCodes, schema } from './helpers';

export default function Example() {
  const [mutationErrors, setMutationErrors] = useState(null);
  const navigate = useNavigate();

  const { account } = useAccount();
  const { cartId, removeCart } = useCart();
  const { currentUser } = useAuth();

  const [create, { error, loading }] = useMutation(CREATE_ORDER);
  const [createCartTransaction, { error: ctError, loading: ctLoading }] =
    useMutation(CREATE_CART_TRANSACTION);

  const matchedUser = useMemo(
    () => account?.users?.find((u) => u.email === currentUser?.email),
    [account?.users, currentUser?.email],
  );

  const {
    register,
    handleSubmit,
    formState,
    control,
    getValues,
    setValue,
    getFieldState,
    ...rest
  } = useForm({
    resolver: yupResolver(schema()),
    defaultValues: initialValues({ account, cartId, user: matchedUser }),
    values: initialValues({ account, cartId, user: matchedUser }),
    mode: 'onBlur',
  });

  async function submitOrder(
    shippingAddress,
    deliveryMethod,
    cartTransactionId,
  ) {
    try {
      const orderParams = {
        variables: {
          input: {
            input: {
              shippingAddress,
              deliveryMethod,
              cartId,
              cartTransactionId,
            },
            clientMutationId: crypto.randomUUID(),
          },
        },
      };

      const { data } = await create(orderParams);

      const mErrors = data.createOrder.errorMessages;

      if (mErrors.length > 0) {
        setMutationErrors(mErrors);
      } else {
        setMutationErrors(null);
        removeCart();
        navigate(`/checkout/success/${data.createOrder.order.hexCode}`);
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function submitRecord(values) {
    const { creditCard, billingAddress, shippingAddress, deliveryMethod } =
      values;

    try {
      const transactionParams = {
        variables: {
          input: {
            input: { cartId, creditCard, billingAddress },
            clientMutationId: crypto.randomUUID(),
          },
        },
      };

      const { data: transactionData } =
        await createCartTransaction(transactionParams);

      const tErrors = transactionData.createCartTransaction.errorMessages;

      if (tErrors.length > 0) {
        setMutationErrors(tErrors);
        return;
      }

      const { state, status, code, id } =
        transactionData.createCartTransaction.cartTransaction;

      if (state === 'failed') {
        setMutationErrors([
          {
            path: status,
            message: responseCodes[code].message,
          },
        ]);
        return;
      }

      await submitOrder(shippingAddress, deliveryMethod, id);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <div className="mb-24">
      <div className="mx-auto max-w-7xl gap-y-4 sm:pt-4">
        <div className="mx-auto max-w-2xl px-4 pt-8 pb-24 sm:px-6 lg:max-w-7xl lg:px-8">
          <FormProvider
            {...rest}
            control={control}
            register={register}
            handleSubmit={handleSubmit}
            formState={formState}
            getValues={getValues}
            setValue={setValue}
            getFieldState={getFieldState}
          >
            <form
              className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16"
              onSubmit={handleSubmit(submitRecord)}
              noValidate
            >
              <CheckoutForm />

              <CheckoutCart
                error={error}
                mutationErrors={mutationErrors}
                loading={loading}
                ctError={ctError}
                ctLoading={ctLoading}
              />
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
}
