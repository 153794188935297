import { gql } from '@apollo/client';
import { ProductionOrderListInfo } from '../fragments';

export default gql`
  query productionOrders($status: String, $legacy: Boolean) {
    productionOrders(status: $status, legacy: $legacy) {
      ...ProductionOrderListInfo
    }
  }
  ${ProductionOrderListInfo}
`;
