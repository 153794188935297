import { useMutation } from '@apollo/client';
import { DESTROY_ACCOUNT_ADDRESS } from '@retainerclub/shared-api';
import PropTypes from 'prop-types';
import { DeleteButton } from '../../../Button';

function DestroyShippingAddress({ id, refetch = null }) {
  const [destroy] = useMutation(DESTROY_ACCOUNT_ADDRESS);

  async function handleAddressDestroy() {
    try {
      const params = {
        variables: {
          input: {
            id,
            clientMutationId: crypto.randomUUID(),
          },
        },
      };

      const { data } = await destroy(params);

      const { accountAddress } = data.destroyAccountAddress;

      if (accountAddress && refetch) {
        refetch();
      }
    } catch (err) {
      console.error(err);
    }
  }

  return <DeleteButton onClick={() => handleAddressDestroy()} size="sm" />;
}

DestroyShippingAddress.propTypes = {
  id: PropTypes.string.isRequired,
  refetch: PropTypes.func,
};

export default DestroyShippingAddress;
