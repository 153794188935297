import { gql } from '@apollo/client';

const AccountListInfoShared = gql`
  fragment AccountListInfoShared on Account {
    id
    accountNumber
    slug
    active
    users {
      id
      firstName
      lastName
    }
    patients {
      id
      firstName
      lastName
    }
    createdAt
    updatedAt
  }
`;

export default AccountListInfoShared;
