import { XCircleIcon } from '@heroicons/react/20/solid';
import { useRouteError } from 'react-router-dom';

function ErrorPage() {
  const error = useRouteError();

  console.error(error);

  return (
    <div className="mx-auto mt-20 max-w-2xl px-4 sm:px-6 lg:px-8">
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="font-medium text-red-800 text-sm">Oops!</h3>
            <div className="mt-2 text-red-700 text-sm">
              <p>Sorry, an unexpected error has occurred.</p>
              <ul className="list-disc space-y-1 pl-5">
                <li>{error.statusText || error.message}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;
