import { yupResolver } from '@hookform/resolvers/yup';
import { useToast } from '@retainerclub/hooks';
import { updatePassword } from 'aws-amplify/auth';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, Input, SubmitButton } from '../../../shared';
import { initialValues, schema } from './helpers';

function UpdatePassword() {
  const [error, setError] = useState();
  const { setToast } = useToast();
  const { t } = useTranslation('ui');

  const {
    register,
    handleSubmit,
    reset,
    formState: {
      isLoading,
      isSubmitting,
      isSubmitSuccessful,
      isValid,
      isDirty,
    },
    formState,
    control,
    getValues,
    setValue,
    ...rest
  } = useForm({
    resolver: yupResolver(schema()),
    defaultValues: initialValues(),
    mode: 'onBlur',
  });

  useEffect(() => {
    if (isSubmitSuccessful && error === null) {
      reset({ currentPassword: '', password: '' });
      setToast({
        show: true,
        title: 'Success',
        message: 'Your password has been updated',
        success: true,
      });
    }
  }, [error, isSubmitSuccessful, reset, setToast]);

  async function handlePassword(data) {
    setError(null);

    try {
      await updatePassword({
        oldPassword: data.currentPassword,
        newPassword: data.password,
      });
    } catch (err) {
      setError(err.message);
    }
  }

  return (
    <FormProvider
      {...rest}
      control={control}
      register={register}
      handleSubmit={handleSubmit}
      formState={formState}
      getValues={getValues}
      setValue={setValue}
    >
      <form onSubmit={handleSubmit(handlePassword)} noValidate>
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 pb-8 md:grid-cols-3">
          <div>
            <h2 className="font-semibold text-base text-black leading-7">
              {t('Change Your Password')}
            </h2>
            <p className="mt-1 text-black/70 text-sm leading-6">
              {t('Enter your current password and a new one')}.
            </p>
          </div>

          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
            <Input
              className="sm:col-span-3"
              type="password"
              autoComplete="current-password"
              required
              name="currentPassword"
              label="Current Password"
            />

            <Input
              className="sm:col-span-3"
              type="password"
              autoComplete="new-password"
              required
              name="password"
              label="New Password"
            />

            {error && (
              <div className="sm:col-span-6">
                <ErrorMessage error={error} />
              </div>
            )}
            <div className="sm:col-span-6">
              <div className="flex items-center justify-end">
                <SubmitButton
                  isLoading={isLoading}
                  isSubmitting={isSubmitting}
                  isValid={isValid}
                  isDirty={isDirty}
                  label="Update Password"
                  full={false}
                  notRounded
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}

export default UpdatePassword;
